import React from 'react';
import PropTypes from 'prop-types';
import { Link as LinkAnchor } from 'react-scroll';

import '../../styles/ButtonSquare.scss';


const ButtonSquare = (props) => {
  const {
    link, children,
  } = props;
  return (
    <LinkAnchor to={link.substring(1)} smooth="true">
      <div className="scroll_button">
        {children}
      </div>
    </LinkAnchor>
  );
};

ButtonSquare.defaultProps = {
  children: null,
};

ButtonSquare.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default ButtonSquare;
