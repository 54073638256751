import React from 'react';
import PropTypes from 'prop-types';
import ButtonSquare from './ButtonSquare';
import scrollIcon from './assets/img/button_scroll_icon.png';

const buttonStyle = {
  background: `url(${scrollIcon}) no-repeat center`,
  width: '55px',
  height: '55px',
  cursor: 'pointer',
};

const ButtonScroll = (props) => {
  const { link } = props;
  return (
    <ButtonSquare link={link}>
      <div style={buttonStyle} />
    </ButtonSquare>
  );
};


ButtonScroll.defaultProps = {
  link: '#',
};

ButtonScroll.propTypes = {
  link: PropTypes.string,
};

export default ButtonScroll;
