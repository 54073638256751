import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import ButtonScroll from '../components/ButtonSquare/ButtonScroll';
import ButtonCircle from '../components/ButtonCircle';
import Slider from '../components/Slider';
import logoQualibat from '../assets/img/logo_qualibat.png';


function splitTitleInTwo(title) {
  const part1 = title.split(' ')[0];
  const part2 = title.substr(part1.length);
  return [part1, part2];
}

function formatCategoryPhotos(photos) {
  return photos.map(photo => (
    <Img
      key={photo.id}
      fluid={photo.fluid}
      style={{ width: `calc((${photo.width} / ${photo.height}) * (0.80*450px)` }}
    />
  ));
}

const IndexPage = (props) => {
  const {
    data: {
      datoCmsVillaStanding,
      datoCmsLogementCollectif,
      datoCmsBatimentIndustriel,
      datoCmsBatimentTertiairePublic,
      datoCmsAccueil: {
        titreVillasStanding,
        titreLogementsCollectifs,
        titreBatimentsIndustriels,
        titreBatimentsTertiairesPublic,
        photosCarousel,
        descriptionLogementsCollectifs,
        photosLogementsCollectifs,
        descriptionVillasStanding,
        photosVillasStanding,
        descriptionBatimentTertiairePublic,
        photosBatimentsTertiairesPublic,
        descriptionBatimentsIndustriels,
        photosBatimentsIndustriels,
        texteEntreprise,
        logosPartenaires,
      },
    },
  } = props;
  const logementsCollectifsPhotosElements = formatCategoryPhotos(photosLogementsCollectifs);
  const villasStandingPhotosElements = formatCategoryPhotos(photosVillasStanding);
  const batimentTertiairePublicPhotosElements = formatCategoryPhotos(photosBatimentsTertiairesPublic);
  const batimentsIndustrielsPhotosElements = formatCategoryPhotos(photosBatimentsIndustriels);
  const logosPartnersElements = logosPartenaires.map(photo => (
    <Img
      key={photo.id}
      fluid={photo.fluid}
      className="partners_logo"
      style={{ width: `calc((${photo.width} / ${photo.height}) * (13vh))` }}
    />
  ));
  return (
    <Layout>
      <div className="index_slider">
        <Slider photos={photosCarousel} />
      </div>
      <div className="buttonscroll_container">
        <ButtonScroll link="#portfolio" />
      </div>
      <div className="category left" id="portfolio">
        <div className="img_container">
          <div>
            {logementsCollectifsPhotosElements}
          </div>
        </div>
        <ButtonCircle link={datoCmsLogementCollectif.slug} type="plus" />
        <div className="infos_container">
          <h2>
            {splitTitleInTwo(titreLogementsCollectifs)[0]}
            <br />
            <strong>
              {splitTitleInTwo(titreLogementsCollectifs)[1]}
            </strong>
          </h2>
          <p>
            {descriptionLogementsCollectifs}
          </p>
          <Link to={datoCmsLogementCollectif.slug} className="button_plus_mobile">Nos réalisations</Link>
        </div>

      </div>
      <div className="category right">
        <div className="infos_container">
          <div>
            <h2>
              {splitTitleInTwo(titreVillasStanding)[0]}
              <br />
              <strong>
                {splitTitleInTwo(titreVillasStanding)[1]}
              </strong>
            </h2>
            <p>
              {descriptionVillasStanding}
            </p>
            <Link to={datoCmsVillaStanding.slug} className="button_plus_mobile">Nos réalisations</Link>
          </div>
        </div>
        <ButtonCircle link={datoCmsVillaStanding.slug} type="plus" />
        <div className="img_container">
          <div>
            {villasStandingPhotosElements}
          </div>
        </div>

      </div>
      <div className="category left">
        <div className="img_container">
          <div>
            {batimentTertiairePublicPhotosElements}
          </div>
        </div>
        <ButtonCircle link={datoCmsBatimentTertiairePublic.slug} type="plus" />
        <div className="infos_container">
          <h2>
            {splitTitleInTwo(titreBatimentsTertiairesPublic)[0]}
            <br />
            <strong>
              {splitTitleInTwo(titreBatimentsTertiairesPublic)[1]}
            </strong>
          </h2>
          <p>
            {descriptionBatimentTertiairePublic}
          </p>
          <Link to={datoCmsBatimentTertiairePublic.slug} className="button_plus_mobile">Nos réalisations</Link>
        </div>
      </div>
      <div className="category right">
        <div className="infos_container">
          <div>
            <h2>
              {splitTitleInTwo(titreBatimentsIndustriels)[0]}
              <br />
              <strong>
                {splitTitleInTwo(titreBatimentsIndustriels)[1]}
              </strong>
            </h2>
            <p>
              {descriptionBatimentsIndustriels}
            </p>
            <Link to={datoCmsBatimentIndustriel.slug} className="button_plus_mobile">Nos réalisations</Link>
          </div>
        </div>
        <ButtonCircle link={datoCmsBatimentIndustriel.slug} type="plus" />
        <div className="img_container">
          <div>
            {batimentsIndustrielsPhotosElements}
          </div>
        </div>
      </div>
      <img src={logoQualibat} alt="logo qualibat" className="company_description_qualibatlogo" />
      <div className="company_description" id="entreprise">
        <h2>L'entreprise</h2>
        <p>
          {texteEntreprise}
        </p>
      </div>
      <div className="partners" id="partenaires">
        <h2>Nos Partenaires</h2>
        <div className="logo_container">
          {logosPartnersElements}
        </div>
      </div>
    </Layout>

  );
};

export default IndexPage;

export const query = graphql`
query Accueil {
  datoCmsVillaStanding {
    slug
  }
  datoCmsLogementCollectif {
    slug
  }
  datoCmsBatimentIndustriel {
    slug
  }
  datoCmsBatimentTertiairePublic {
    slug
  }
  datoCmsAccueil {
    titreVillasStanding,
    titreLogementsCollectifs,
    titreBatimentsIndustriels,
    titreBatimentsTertiairesPublic
    photosCarousel {
      id
      width
      height
      fluid(maxWidth: 1400, imgixParams: {fm: "jpg", auto: "compress"}) {
        ...GatsbyDatoCmsFluid
      }
    }
    descriptionLogementsCollectifs
    photosLogementsCollectifs {
      id
      width
      height
      fluid(maxWidth: 700, imgixParams: {fm: "jpg", auto: "compress"}) {
        ...GatsbyDatoCmsFluid
      }
    }
    descriptionVillasStanding
    photosVillasStanding {
      id
      width
      height
      fluid(maxWidth: 700, imgixParams: {fm: "jpg", auto: "compress"}) {
        ...GatsbyDatoCmsFluid
      }
    }
    descriptionBatimentTertiairePublic
    photosBatimentsTertiairesPublic {
      id
      width
      height
      fluid(maxWidth: 700, imgixParams: {fm: "jpg", auto: "compress"}) {
        ...GatsbyDatoCmsFluid
      }
    }
    descriptionBatimentsIndustriels
    photosBatimentsIndustriels {
      id
      width
      height
      fluid(maxWidth: 700, imgixParams: {fm: "jpg", auto: "compress"}) {
        ...GatsbyDatoCmsFluid
      }
    }
    texteEntreprise
    logosPartenaires {
      id
      width
      height
      fluid(maxWidth: 700, imgixParams: {fm: "jpg", auto: "compress"}) {
        ...GatsbyDatoCmsFluid
      }
    }
  }
}

`;
