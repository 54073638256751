import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import '../styles/ButtonCircle.scss';


const ButtonCircle = (props) => {
  const {
    link, type, callback,
  } = props;

  if (!callback) {
    return (
      <Link to={link} className="button_circle_link">
        <div className={`button_circle ${type}`} />
      </Link>
    );
  }
  return (
    <a onClick={callback} to={link} className="button_circle_link">
      <div className={`button_circle ${type}`} />
    </a>
  );
};

ButtonCircle.defaultProps = {
  callback: null,
};

ButtonCircle.propTypes = {
  link: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

export default ButtonCircle;
